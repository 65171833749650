import { HttpClient, HttpHeaders, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse , HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core'; // to make its methos available in all other methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ToastrService } from 'ngx-toastr'; // add toster service
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
  public currentUser: any;
  constructor(
    private http: HttpClient,
    private toastrService: ToastrService) {
  }
  
  /**
   * Generic Get Method
   * @param apiUrl - Api URL
   */
  getApi(apiUrl: string) {
    return this.http.get(apiUrl, { headers: { 'content-type': 'application/json'} })
      .map(response => response)
      .catch((e: any) => Observable.throw(this.errorHandler(e, apiUrl)));
  }

  /**
   * Generic Post Method
   * @param apiUrl - Api URL 
   * @param body - Body of API
   */
  postApi(apiUrl: string, body: any) {
    return this.http.post<any>(apiUrl, body, { headers: { 'content-type': 'application/json'} })
      .map(response => response)
      .catch((e: any) => Observable.throw(this.errorHandler(e, apiUrl)));
  }

  /**
   * Generic PUT Method
   * @param apiUrl - Api URL 
   * @param body - Body of API
   */
  putApi(apiUrl: string, body: any) {
    return this.http.put<any>(apiUrl, body, { headers: { 'content-type': 'application/json'} })
      .map(response => response)
      .catch((e: any) => Observable.throw(this.errorHandler(e, apiUrl)));
  }

  // Delete method to Access api of Delete type
  delete<T>(url: string): Observable<any> {
    return this.http.delete<any>(url)
      .map(response => response)
      .catch((e: any) => Observable.throw(this.errorHandler(e, url)));
  }

  // Patch method to Access api of Patch type
  patch<T>(url: string, body: string): Observable<any> {
    return this.http.patch<any>(url, body)
      .map(response => response)
      .catch((e: any) => Observable.throw(this.errorHandler(e, url)));
  }

  // catch error if API throws any error
  errorHandler(error: any, apiURl: string): void {
    console.log(error.message + apiURl)
  }

  loginerrorHandler(error: any, apiURl: string): void {
    this.toastrService.error('Invalid credentials!')
  }

  postApiWitoutToken(apiUrl: string, body: any) {
    return this.http.post<any>(apiUrl, body)
      .map(response => response)
      .catch((e: any) => Observable.throw(this.errorHandler(e, apiUrl)));
  }

}

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = ''; // get the token from a service

    if (!req.headers.has('Content-Type')) {
      req = req.clone({});
    }
    return next.handle(req);
  }
 

}
