import { Component, OnInit, Input, Compiler } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Please provide your mobile number';
  applicationLoadLoader: boolean = true;
  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    setTimeout( ()=>{
      this.applicationLoadLoader = false;
    }, 2000);
  }
}
