import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppBootstrapModule } from './app-bootstrap/app-bootstrap.module'; // import the appbootstrap module
import { AppRoutingModule } from './app-routing.module'; // import app routing module
import { SharedModule } from './shared/shared.module'; // import the shared modules
import { ApiService, Interceptor } from './common/services/rest-api/api.service';
import { AuthGuardService } from './common/services/auth-guard.service';
import { AuthRestService } from './common/services/auth/auth-rest.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppBootstrapModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  exports: [SharedModule, NgbModule, BrowserModule, BrowserAnimationsModule],
  providers: [AuthRestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    ApiService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
