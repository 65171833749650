import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class AuthRestService {

  constructor(private apiService: ApiService) {
  }

  /**
   * Generic Get Method
   * @param apiUrl api url
   */
  public getApi(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
    .toPromise().then(res => {
      if (res) {
        let response = {
          success: true,
          data: res
        };
        return response;
      }
    }).catch(error => {
      let badRequestError = {
        success: false,
        data: null,
        message: "Server Error, Something went wrong!"
      };
      return badRequestError;
    });
  }  

  /**
   * Generic Post Method
   * @param apiUrl api url
   * @param data request data
   */
  public postApi(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise().then(res => {
        if (res) {
          let response = {
            success: true,
            data: res
          };
          return response;
        }
      }).catch(error => {
        let badRequestError = {
          success: true,
          data: null,
          message: "Server Error, Something went wrong!"
        };
        return badRequestError;
      });
  }

  /**
   * Generic PUT Method
   * @param apiUrl api url
   * @param data request data
   */
  public putApi(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise().then(res => {
        if (res) {
          let response = {
            success: true,
            data: res
          };
          return response;
        }
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: "Server Error, Something went wrong!"
        };
        return badRequestError;
      });
  }

  /**
   * Function to delete
   */
  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
