import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AuthGuardService } from './common/services/auth-guard.service';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
        path: '', children: [
            { path: '', component: HeaderComponent, outlet: 'header' },
            { path: '', component: FooterComponent, outlet: 'footer' },
            { path: '', loadChildren: './modules/auth/auth.module#AuthModule' },
        ],
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
